import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Box from 'reusecore/src/elements/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';

import image from 'common/src/assets/image/crypto/hero-logo.svg';
import imageAlt from 'common/src/assets/image/crypto/hero-logo-dark.svg';

const Navbar = ({
  navbarStyle,
  logoStyle,
  button,
  row,
  menuWrapper,
  previewData,
}) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const Data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Navbar EN" } }) {
        frontmatter {
          tabs {
            label
            offset
            path
          }
          cta {
            text
            path
          }
        }
      }
    }
  `)['markdownRemark']['frontmatter'];

  if (previewData) {
    navbarStyle['backgroundColor'] = 'grey';
  }

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <img
            style={logoStyle}
            src={image}
            alt={'Oddshero Logo'}
            className="main-logo"
          />
          <img
            style={logoStyle}
            src={imageAlt}
            alt={'Oddshero Logo'}
            className="logo-alt"
          />
          {/*
          <Logo
            href={'/'}
            logoSrc={image}
            title={'Oddshero Logo'}
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href={'/'}
            logoSrc={imageAlt}
            title={'Oddshero Logo'}
            logoStyle={logoStyle}
            className="logo-alt"
          />
          */}
          <Box {...menuWrapper}>
            <ul className="main_menu">
              {Data.tabs.map((menu, index) => (
                <li key={`menu-item-${index}`}>
                  <a href={menu.path}>{menu.label}</a>
                </li>
              ))}
            </ul>

            <a
              href="https://app.oddshero.io/login"
              className="navbar_button navbar_login_button"
            >
              <Button {...button} title="Login" />
            </a>

            <a href={Data.cta.path} className="navbar_button">
              <Button {...button} title={Data.cta.text} />
            </a>

            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ul className="mobile_menu">
                {Data.tabs.map((menu, index) => (
                  <li key={`menu-item-${index}`}>
                    <a href={menu.path}>{menu.label}</a>
                  </li>
                ))}
              </ul>

              <a
                href="https://app.oddshero.io/login"
                className="navbar_drawer_button navbar_drawer_login_button"
              >
                <Button {...button} title="Login" />
              </a>

              <a href={Data.cta.path} className="navbar_drawer_button">
                <Button {...button} title={Data.cta.text} />
              </a>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
    maxHeight: '40px',
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
